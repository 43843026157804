// import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueItem } from "../../../models/TextValueItem";
import { AnagraficaCommerciali } from "@/models/commerciali/anagraficaCommerciali";
import permessi from "@/config/permessi";
import editForm from "@/components/grid/edit/editForm.vue";

const _ENDPONT = api.endpoint.COMMERCIALI;

@Component({})
export default class commercialiPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Commerciali.COMMERCIALI];
	}
	@Prop({ default: () => true }) showDetail: boolean;
	@Prop({ default: () => true }) showDelete: boolean;
	@Prop({ default: () => false }) disalbleEditRagioneSociale: boolean;
	@Prop({ default: () => false }) disableEditCancellato: boolean;
	@Prop({ default: () => true }) showAddNew: boolean;
	editItem: AnagraficaCommerciali = new AnagraficaCommerciali();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = true;

	tipiPremio: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{
				field: "ragioneSociale",
				title: this.$i18n.t("generico.denominazione").toString(),
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				editDisabled: this.disalbleEditRagioneSociale,
				validation: { required: true, msg: this.$i18n.t("generico.campoRichiesto").toString() + ": " + this.$i18n.t("generico.denominazione").toString() }
			},
			{
				field: "commissioneTipoID",
				title: this.$i18n.t("generico.tipoCommissione").toString(),
				width: 200,
				values: this.tipiPremio,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				sortBy: "tipoPremio",
				itemsForFilter: this.tipiPremio,
				filterType: "select",
				editType: "select",
				editSource: this.tipiPremio,
				validation: { required: true, msg: this.$i18n.t("generico.campoRichiesto").toString() + ": " + this.$i18n.t("generico.tipoCommissione").toString() }
			},
			{ field: "tipoPremio", title: this.$i18n.t("generico.tipoCommissione").toString(), hidden: true, editHide: true, export: true },
			{
				field: "commissione",
				title: this.$i18n.t("generico.commissione").toString(),
				width: 150,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				headerType: "numeric",
				filterable: true,
				export: true,
				//type: "numeric",
				type: "renderfunction", 
				renderFunction: this.renderCommissione,
				validation: { required: true, msg: this.$i18n.t("generico.campoRichiesto").toString() + ": " + this.$i18n.t("generico.commissione").toString() }
			},
			{
				field: "dataInizioRapportoWI",
				title: this.$i18n.t("generico.inizioRapporto").toString(),
				width: 200,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate,
				validation: { required: true, msg: this.$i18n.t("generico.campoRichiesto").toString() + ": " + this.$i18n.t("generico.inizioRapporto").toString() }
			},
			{
				field: "dataFineRapportoWI",
				title: this.$i18n.t("generico.fineRapporto").toString(),
				width: 200,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "cancellato",
				title: this.$i18n.t("generico.nonAttivo").toString(),
				width: 100,
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				disabled: true,
				editDisabled: this.disableEditCancellato
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "ragioneSociale", dir: "asc" }];

	created() {
		var self = this;

		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => (self.tipiPremio = res))])
			.then(responses => {
				if (self.showOnlyActive) {
					this.filter.filters.push({
						field: "cancellato",
						operator: "eq",
						value: "!!false!!"
					});
				}
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() {}

	addNewRow() {
		let ac = new AnagraficaCommerciali();

		this.onEdit(ac);
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "cancellato";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!false!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.ragioneSociale}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributore")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new AnagraficaCommerciali(), item);
		self.editSubTitle = item.ragioneSociale;
		self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	onSaveEdit() {
		var self = this;

		var err = (this.$refs.editForm as editForm).validate();

		if (err) {
			var msgs = [];
			for (var i = 0; i < err.length; i++) msgs.push(this.$createElement("div", null, err[i]));

			self.$bvToast.toast(msgs, {
				variant: "danger",
				title: self.$i18n.t("msg.erroreGenerico").toString(),
				solid: true
			});
			return;
		}

		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.commerciali") }).toString();
		self.setGridRow<AnagraficaCommerciali>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => (self.showEditModal = false));
	}

	get detailTemplate() {
		if (this.showDetail) return "detailTemplate";
		else return "";
	}
}
