export class AnagraficaCommerciali {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.ragioneSociale = "";
		this.dataInizioRapportoWI = null;
		this.dataFineRapportoWI = null;
		this.cancellato = false;
		this.commissione = null;
		this.commissioneTipoID = null;
	}

	itemID: number;
	ragioneSociale: string;
	dataInizioRapportoWI: Date | string | null;
	dataFineRapportoWI: Date | string | null;
	cancellato: boolean;
	commissione: number | null;
	commissioneTipoID: number | null;
}
